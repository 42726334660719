@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #00a4da;
  --secondary: #002a5c;
}

@layer base {
  * {
    @apply border-borderColor;
  }
  body {
    @apply bg-white text-textColor;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Styling for the search textarea */
.search-textarea {
  width: 100%;
  max-width: 100%;
  padding: 1rem;
  font-size: 1.125rem; /* Slightly larger text */
  line-height: 1.6;
  border: 1px solid #ccc;
  border-radius: 8px;
  resize: none; /* Prevent manual resizing */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Adjustments for spacing */
.search-container {
  margin-top: 1rem;
  width: 100%;
  max-width: 4xl; /* Match width to the hero text */
}

/* Carousel container */
.carousel-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  overflow: visible;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

/* Blog listing page styling */
.blog-listing-page {
  max-width: 1000px;
  margin: auto;
}

.filter-section {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-section input[type="text"] {
  padding: 8px;
  width: 100%;
  max-width: 300px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tag-filter .tag-button {
  padding: 6px 12px;
  background-color: #e2e8f0;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tag-filter .tag-button:hover,
.tag-filter .tag-button.active-tag {
  background-color: #4299e1;
  color: white;
}

/* Blog Grid Container for Homepage */
.blog-grid-container {
  display: grid;
  gap: 2rem;
  justify-content: center;
  margin-top: 2rem;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .blog-grid-container {
    grid-template-columns: repeat(3, 1fr); /* Two cards per row for large screens */
  }
}

@media (max-width: 768px) {
  .blog-grid-container {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}

/* Blog Card styling */
.blog-card {
  width: 100%;
  max-width: 450px;
  height: 600px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f8f8f8;
  box-shadow: 0px 4px 8px rgba(0, 0, 95, 0.2);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.blog-card:hover {
  transform: scale(1.05);
}

/* Image occupies top 50% */
.blog-card img {
  width: 100%;
  height: 50%;
  object-fit: cover;
}

/* Content area occupies bottom 50% */
.blog-card-content {
  height: 50%;
  padding: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.blog-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #002a5d;
  margin-bottom: 8px;
  line-height: 1.3;
}

.blog-card-excerpt {
  font-size: 0.95rem;
  color: #555;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

/* Ensures full-width layout on smaller screens */
@media only screen and (max-width: 768px) {
  .blog-card {
    width: 100%;
    max-width: 100%; /* Makes sure card takes full width */
    height: auto; /* Allows height to adjust based on content */
  }
}


/* Styling for the navigation arrows */
.slick-prev, .slick-next {
  z-index: 5; /* Ensure arrows are above cards */
  background-color: #ffffff;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

.slick-prev:hover, .slick-next:hover {
  background-color: #f0f0f0;
}

/* General layout adjustment */
.hero {
  margin-top: 1rem;
}

.hero h2 {
  margin-bottom: 0.1rem;
}

/* Logo placement adjustments */
.logo-container {
  position: absolute;
  top: 0;
  left: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logo-tagline {
  font-size: 1rem;
  color: #555;
  margin-top: 5px;
}

/* Search Input styling */
.search-input {
  width: 100%;
  max-width: 600px; /* Adjust to make it larger */
  height: 3rem; /* Increase height */
  font-size: 1.1rem; /* Increase font size */
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}


.search-container {
  margin-top: 0.1rem;
}


.search-input {
  width: 500px;
  max-width: 80%;
  height: 50px;
  padding: 10px;
  font-size: 1.1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .search-input {
    width: 90%;
  }
}

.nav-search-input {
  width: 100%;
  min-height: 36px;
  max-height: calc(36px * 5); /* Expands up to 5 rows */
  overflow-y: auto; /* Allows scrolling after 5 rows */
  padding: 8px;
  border: 1px solid #c6c7c4;
  border-radius: 4px;
  resize: none; /* Prevents manual resizing */
  transition: all 0.3s ease;
  font-size: 16px;
}





/* Responsive adjustments */
@media only screen and (max-width: 1024px) {
  .carousel-container {
    padding: 0 1rem;
  }
  .blog-card {
    width: 350px;
    height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .blog-card {
    width: 90%;
    height: auto;
  }
}
.icon {
  width: 25px;
  height: 25px;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  /* margin: 50px 0; */
  height: 4px;
}

*::-webkit-scrollbar-track {
  /* background: var(--primary); */
  background-color: #ffffff;
  border-radius: 2px;
}

*::-webkit-scrollbar-thumb {
  /* background-color: var(--secondary); */
  /* border: 3px solid var(--primary); */
  background-color: #dadada;
  border-radius: 8px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.thin-scrollbar::-webkit-scrollbar-track {
  margin: 0.5rem;
}

body {
  font-family: "Mulish", sans-serif;
}

.Toastify__close-button {
  color: gray !important;
}

.Toastify__toast-body > div:last-child {
  color: black !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #e4f2ff !important;
  color: #5296d5 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #eaffe3 !important;
  color: green !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #fff8e0 !important;
  color: #eac645 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #ffecea !important;
  color: #d65745 !important;
}

select {
  background-color: transparent;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;

  outline: none;
  letter-spacing: 0.005em;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  padding-right: 40px;
  padding-left: 10px;
  background-image: url("/public/selectArrow.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 15px;

  border: 1px solid #c6c7c4;
  border-radius: 6px;
  color: #11142d;
  height: 42px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* applies to align avatar group in the dashboard to the left side */
.MuiAvatarGroup-root {
  flex-direction: row !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Input Desighn  */

.form-group input {
  /* padding: 0; */
  position: relative;
  height: initial;
  width: initial;
  margin-bottom: 0;
  /* display: none; */
  opacity: 0;
  cursor: pointer;
  left: 15px;
  z-index: 10;
}

.form-group label {
  position: absolute;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  appearance: none;
  background-color: transparent;
  border: 1px solid gray;
  border-radius: 3px;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 6px;
  width: 6px;
  height: 10px;
  border: solid #00a4da;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

@media only screen and (max-width: 320px) {
  .img {
    width: 500px;
  }

  select {
    font-size: 12px !important;
  }

  .card_mb {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 390px) {
  .profile-upload_pd {
    /* flex-wrap: wrap; */
    padding-top: 3rem;
  }

  .input-wrap {
    flex-wrap: wrap;
  }

  .padding-input {
    padding-left: 0;
  }

  .padding-input-t {
    padding-top: 1.5rem;
  }

  .padding-input-b {
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 376px) {
  .banner {
    width: 100%;
    padding: 1rem;
  }

  select {
    font-size: 14px !important;
  }

  .img {
    width: 150px;
  }

  .footerl {
    font-size: 14px;
  }

  .card_mb {
    margin-bottom: 1.2rem;
  }

  p {
    font-size: 16px !important;
  }

  h1 {
    font-size: 22px !important;
  }

  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 425px) {
  .banner {
    width: 100%;
    padding: 1rem;
  }

  select {
    font-size: 14px !important;
  }

  .img {
    width: 100px;
  }

  .help {
    background-position: center;
  }

  p {
    font-size: 16px !important;
  }

  h1 {
    font-size: 22px !important;
  }

  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 14px;
  }

  .profile-upload_pd {
    padding-top: 3rem;
  }

  .input-wrap {
    flex-wrap: wrap;
  }

  .padding-input {
    padding-left: 0;
  }

  .profile-form {
    /* padding-right: 8%; */
  }

  .margin-btn {
    margin-left: -38%;
  }

  .card_mb {
    margin-bottom: 1.2rem;
  }
}

@media only screen and (max-width: 768px) {
  .banner {
    width: 100%;
    padding: 1rem;
  }

  .help {
    background-position: center;
    height: 50vh;
  }

  .help1 {
    display: flex;
    align-items: center;
  }

  .card_mb {
    margin-bottom: 1.2rem;
  }

  .helpp {
    font-size: 20px !important;
  }

  .footerl {
    margin-left: 10px;
    margin-right: 10px;
  }

  p {
    font-size: 16px !important;
  }

  h1 {
    font-size: 22px !important;
  }

  h2 {
    font-size: 14px;
  }

  h3 {
    font-size: 14px;
  }

  h4 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1440px) {
  .footerl {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 1440px) {
  .cards-width {
    width: 320px !important;
  }
}

@media only screen and (max-width: 639px) {
  .popup-modal {
    /* width: 24rem; */
  }

  .popup-modal-ad-card {
    width: 22rem;
    margin-top: -2rem;
  }
}

@media only screen and (max-width: 1024px) {
  .cards-width {
    flex-wrap: wrap;
    width: 284px;
  }
}

@media only screen and (min-width: 1780px) {
  .card_desk {
    width: 400px !important;
    font-size: 29px !important;
  }
}

/* removed border form mui input when error  */
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #c6c7c4 !important;
}

.MuiAutocomplete-popper {
  /* background-color: #11142d; */
  min-width: 180px !important;
  overflow: visible !important;
}

.MuiAutocomplete-listbox {
  overflow: visible !important;
  min-width: fit-content !important;
}

.MuiAutocomplete-listbox li {
  min-width: 80px !important;
}

/* Pyament Card  */
.payment-card {
  background-image: url("./assets/card-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  padding: 9px 16px 21px 24px;
}

/* Sign In Modal */

.signIn-aside {
  background-image: url("./assets/signIn-bg.png"),
    linear-gradient(
      90deg,
      #ffffff 43.25%,
      rgba(255, 255, 255, 0.375291) 81.2%,
      rgba(255, 255, 255, 0) 102.18%
    );
  background-repeat: no-repeat;
  background-position: top right;
  position: relative;
}

.signIn-aside::before {
  content: "";
  position: absolute;
  left: -12px;
  width: 2px;
  height: 74%;
  background-color: #d9d9d980;
}

.signIn-aside ul li::before {
  content: "\2022";
  color: #002a5c;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* Custom Radios */
.radio {
  padding: 18px 22px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #c6c7c4;
}

.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio input[type="radio"] + label {
  color: #5a5a5a;
}

.radio:has(input[type="radio"]:checked) {
  border: 1px solid #00a4da;
  background-color: #64b4d00a;
}

.radio input[type="radio"] + .radio-label:before {
  content: "";
  background: #ffffff;
  border-radius: 100%;
  border: 1px solid #b4b4b4;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: 0;
  margin-right: 1em;
  vertical-align: middle;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}

.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #00a4da;
  box-shadow: inset 0 0 0 4px #ffffff;
}

.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #00a4da;
}

.radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #ffffff;
  border-color: #b4b4b4;
  background: #b4b4b4;
}

.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}

/* Hide publications scollbar */
.publications::-webkit-scrollbar {
  display: none;
}

/*Juricon */

.juricon {
  font-family: "Segoe UI", sans-serif;
}

.juricon .hero-section {
  background-image: url("./pages/others/imgs//juricon/technology-human-touch-background-modern-remake-creation-adam.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.juricon .nav-link.active {
  border-top: 4px solid #00005f;
}

.juricon .tagline-section {
  background-image: url("./pages/others/imgs//juricon/14622\ [Converted].png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.juricon .tagline-section::before {
  content: "";
  position: absolute;
  background-color: #0d61a5;
  width: 10px;
  height: 106px;
  top: -53px;
  left: 13%;
}

.dashedBorder-sm {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%15' height='100%15' fill='white' rx='6' ry='6' stroke='gray' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
  border: 2px dashed #c6c7c4 !important;
  padding: 4px 12px !important;
}

@layer base {
  * {
    @apply border-borderColor outline-none;
  }
  body {
    @apply bg-white text-textColor;
  }
}

/* Focus styles */
*:focus {
  @apply border outline outline-primary/50;
}
